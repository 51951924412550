import axios from 'axios';

axios.default.timeout = 5000;
axios.defaults.headers.post['Content-Type'] = 'application/json';

const instance = axios.create();
instance.defaults.headers.post['Content-Type'] = 'application/json';

axios.interceptors.request.use = instance.interceptors.request.use;
/*  instance.interceptors.request.use(config => {
  if (localStorage.getItem('token')) {
    config.headers.Authorization = `token ${localStorage.getItem('token')}`
      .replace(/(^\")|(\"$)/g, '')
  }
  return config
}, err => {
  return Promise.reject(err)
})  */
// axios拦截响应
instance.interceptors.response.use((response) => response, (err) => Promise.reject(err));

export default {
  getTask(data) {
    return instance.post('/api/gettask', data);
  },
  updateTask(data) {
    return instance.post('/api/updatetask', data);
  },
};
