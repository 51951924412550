import Vue from 'vue';
import VueRouter from 'vue-router';
import store from '@/store/index';

const jwt = require('jsonwebtoken');

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 'Login',
    component: () => import('../views/Login.vue'),
  },
  {
    path: '/dashboard',
    name: 'Dashboard',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/Dashboard.vue'),
  },
  {
    path: '/mobile',
    name: 'Mobile',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/Mobile.vue'),
  },
  {
    path: '/detail/:date',
    name: 'date',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/Detail.vue'),
  },
  {
    path: '/detail',
    name: 'Detail',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/Detail.vue'),
  },
  {
    path: '/reservation',
    name: 'Reservation',
    component: () => import('../views/Reservation.vue'),
  },
  {
    path: '/permission',
    name: 'Permission',
    component: () => import('../views/Permission.vue'),
  },
  {
    path: '/statistic',
    name: 'Statistic',
    component: () => import('../views/Statistic.vue'),
  },
  {
    path: '/statisticdetail',
    name: 'StatisticDetail',
    component: () => import('../views/StatisticDetail.vue'),
  },
  {
    path: '/taskmanage',
    name: 'TaskManage',
    component: () => import('../views/TaskManage.vue'),
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});
async function checkForUpdates() {
  const localVersion = localStorage.getItem('appVersion');
  const response = await fetch('/version.json');
  const data = await response.json();
  const serverVersion = data.version;

  if (localVersion !== serverVersion) {
    // 更新本地存储中的版本号
    localStorage.setItem('appVersion', serverVersion);

    // 刷新页面以获取新的 Service Worker
    window.location.reload();
  }
}
router.beforeEach(async (to, from, next) => {
  await checkForUpdates();
  const hasToken = localStorage.getItem('token');
  const position = localStorage.getItem('position');
  const encodeToken = jwt.decode(hasToken);
  console.log('lalalala', to, from);
  const currentTime = new Date();
  if (to.name !== 'Login') {
    if (encodeToken) {
      if (currentTime.getTime() > encodeToken.exp * 1000) {
        next({ path: '/' });
        console.log('notttttt', currentTime.getTime(), encodeToken.exp);
      } else {
        if (position) {
          store.state.userInfo.position = position;
          store.state.userInfo.name = localStorage.getItem('name');
          store.state.userInfo.email = localStorage.getItem('email');
          store.state.userInfo.id = localStorage.getItem('id');
          store.state.userInfo.region = localStorage.getItem('region');
          store.state.userInfo.image = localStorage.getItem('image');
          if (localStorage.getItem('ability') === undefined || localStorage.getItem('ability') === null) {
            // console.log('do nothing', store.state.userInfo);
            if (position === 'viewer') {
              store.state.sideBar = [
                {
                  name: 'dashboard',
                  label: '主控台',
                  url: '/dashboard',
                  icon: 'mdi-view-dashboard',
                }, {
                  name: 'reservation',
                  label: '預排行程',
                  url: '/reservation',
                  icon: 'mdi-calendar-today',
                }, {
                  name: 'statistic',
                  label: '統計分析',
                  url: '/statistic',
                  icon: 'mdi-chart-box',
                },
              ];
            } else {
              store.state.sideBar = [
                {
                  name: 'dashboard',
                  label: '主控台',
                  url: '/dashboard',
                  icon: 'mdi-view-dashboard',
                }, {
                  name: 'reservation',
                  label: '預排行程',
                  url: '/reservation',
                  icon: 'mdi-calendar-today',
                }, {
                  name: 'permission',
                  label: '權限設定',
                  url: '/permission',
                  icon: 'mdi-lock-outline',
                }, {
                  name: 'statistic',
                  label: '統計分析',
                  url: '/statistic',
                  icon: 'mdi-chart-box',
                }, {
                  name: 'taskManage',
                  label: '任務管理',
                  url: '/taskmanage',
                  icon: 'mdi-cog',
                },
              ];
            }
          } else {
            console.log('cccccccc', localStorage.getItem('ability').length);
            if (localStorage.getItem('ability').length > 0) {
              store.state.userInfo.ability = localStorage.getItem('ability');
              if (store.state.sideBar.some((ele) => ele.label === '施作')) {
                console.log('do nothing');
              } else {
                console.log('do nothing');
                if (position === 'general') {
                  store.state.sideBar = [
                    {
                      name: 'mobile',
                      label: '施作',
                      url: '/mobile',
                      icon: 'mdi-account-wrench',
                    }, {
                      name: 'statistic',
                      label: '統計分析',
                      url: '/statistic',
                      icon: 'mdi-chart-box',
                    },
                  ];
                } else {
                  store.state.sideBar = [
                    {
                      name: 'mobile',
                      label: '施作',
                      url: '/mobile',
                      icon: 'mdi-account-wrench',
                    }, {
                      name: 'dashboard',
                      label: '主控台',
                      url: '/dashboard',
                      icon: 'mdi-view-dashboard',
                    }, {
                      name: 'reservation',
                      label: '預排行程',
                      url: '/reservation',
                      icon: 'mdi-calendar-today',
                    }, {
                      name: 'permission',
                      label: '權限設定',
                      url: '/permission',
                      icon: 'mdi-lock-outline',
                    }, {
                      name: 'statistic',
                      label: '統計分析',
                      url: '/statistic',
                      icon: 'mdi-chart-box',
                    }, {
                      name: 'taskManage',
                      label: '任務管理',
                      url: '/taskmanage',
                      icon: 'mdi-cog',
                    },
                  ];
                }
                // store.state.sideBar.splice(0, 0, {
                //   name: 'mobile',
                //   label: '施作',
                //   url: '/mobile',
                //   icon: 'mdi-account-wrench',
                // });
              }
            }
          }
        } else {
          console.log('no position');
        }
        console.log('okkkkkkk');
        next();
      }
    } else {
      next({ path: '/' });
    }
  } else {
    next();
  }
  // if (currentTime.getTime() <= encodeToken.exp) {
  //   next();
  // } else {
  //   next({ path: '/' })
  // }
});

export default router;
