import Vue from 'vue';
import Vuex from 'vuex';

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    tab: 0,
    removeIndexs: [],
    expiryFire: false,
    region: '北區',
    currentDate: new Date(),
    task: {},
    trafficCategories: [
      {
        id: 1,
        level: 1,
        name: '基隆市',
        planed: 60,
        score: 1,
        children: [
        ],
      }, {
        id: 2,
        level: 1,
        name: '台北市',
        planed: 60,
        score: 1,
        children: [
          {
            id: 3,
            level: 2,
            name: '桃園市',
            planed: 60,
            score: 1,
            children: [
            ],
          }, {
            id: 4,
            level: 2,
            name: '新竹',
            planed: 60,
            score: 2,
            children: [
            ],
          }, {
            id: 5,
            level: 2,
            name: '苗栗縣',
            planed: 60,
            score: 3,
            children: [
            ],
          }, {
            id: 6,
            level: 2,
            name: '宜蘭縣',
            planed: 60,
            score: 1.5,
            children: [
            ],
          },
        ],
      },
    ],
    traffics: [],
    taskCategories: [],
    abilities: [],
    newtaskCategories: [],
    taskList: [],
    userInfo: {},
    sideBar: [],
    members: [],
    newmembers: [
      {
        img: '',
        name: '陳師傅',
        email: 'chen@shihlei.com',
        color: '',
        region: '北區',
        ability: [],
      }, {
        img: '',
        name: '王師傅',
        email: 'wang@shihlei.com',
        color: '',
        region: '南區',
        ability: [],
      }, {
        img: '',
        name: '林師傅',
        email: 'lin@shihlei.com',
        color: '',
        region: '中區',
        ability: [],
      }, {
        img: '',
        name: '徐小姐',
        email: 'ms@shihlei.com',
        color: '',
        region: '總部',
        ability: [],
      }, {
        img: '',
        name: '張師傅',
        email: 'chang@shihlei.com',
        color: '',
        region: '淨水',
        ability: [],
      }, {
        img: '',
        name: '李師傅',
        email: 'lee@shihlei.com',
        color: '',
        region: '大水',
        ability: [],
      },
    ],
    stateKind: '',
    taskDetail: {},
  },
  mutations: {
  },
  actions: {
  },
  modules: {
  },
});
