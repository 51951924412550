<template>
  <v-app>
    <v-navigation-drawer
      v-if="$route.path != '/' && $vuetify.breakpoint.smAndUp"
      v-model="drawer"
      app
      absolute
      permanent
      :mini-variant.sync="mini"
    >
      <div style="width:100%;text-align:center;">
        <v-img
          style="margin:70px auto 0px auto;width:80%"
          src="./assets/logo.png"
        />
      </div>
      <!-- <img alt="logo" style="width:260px;" src="../assets/logo.png"> -->
      <v-list
        style=""
        nav
      >
        <v-list-item-group
          v-model="group"
          active-class="choosen"
        >
          <v-list-item
            v-for="title in $store.state.sideBar"
            :key="title.label"
            @click="goTo(title.url)"
          >
            <v-list-item-icon>
              <v-tooltip right>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    color="#4DBCB8"
                    v-bind="attrs"
                    v-on="on">
                    {{ title.icon }}
                  </v-icon>
                </template>
                <span
                  v-if="mini"
                  style="background-color:grey;padding:10px;opacity:.6;border-radius: 8px;">
                  {{title.label}}
                </span>
              </v-tooltip>
            </v-list-item-icon>
            <v-list-item-title>
              {{ title.label }}
            </v-list-item-title>
          </v-list-item>
        </v-list-item-group>
      </v-list>
      <div class="drawer-footer" style="opacity:.3">
        v {{ version }}
      </div>
    </v-navigation-drawer>
    <v-main style="overflow-y:scroll;">
      <v-app-bar
        id="bar"
        elevation="0"
        style=""
        color="#4DBCB8"
        v-if="$route.path != '/'"
      >
        <v-app-bar-nav-icon
          v-if="$route.path != '/'"
          @click="mini = !mini">
          <span v-if="$vuetify.breakpoint.smAndUp">
            <v-icon color="white" v-if="mini">mdi-forwardburger</v-icon>
            <v-icon color="white" v-else>mdi-backburger</v-icon>
          </span>
          <v-icon color="white" v-else @click="barDialog = true">
            mdi-menu
          </v-icon>
        </v-app-bar-nav-icon>
        <v-spacer></v-spacer>
        <div class="d-flex align-center">
          <span style="color:white;font-size:24px;">排班系統</span>
          <!--<v-img
            alt="Logo"
            class="shrink mr-2"
            style="margin:auto"
            contain
            src="./assets/newLogo.png"
            transition="scale-transition"
            width="240"
          />-->
        </div>

        <v-spacer></v-spacer>
        <v-btn
          elevation="0"
          icon
          style="margin-right:0px;"
        >
          <v-img src="./assets/notify.svg" max-width="16" />
        </v-btn>
        <v-btn
          elevation="0"
          icon
          style="margin-right:0px;"
        >
          <v-img style="" src="./assets/application.svg" max-width="19" />
        </v-btn>
        <v-menu offset-y>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              elevation="0"
              icon
              style="margin-right:50px;"
              v-bind="attrs"
              v-on="on"
            >
              <v-img
                v-if="$store.state.userInfo.image == 'undefined'"
                src="./assets/account.svg"
                max-width="22"
              />
              <v-img
                v-else
                :src="$store.state.userInfo.image"
                max-width="44"
                style="border-radius: 50%;cursor:pointer;"
                :aspect-ratio="1/1"
              />
            </v-btn>
          </template>
          <v-card>
            <v-list>
              <v-list-item>
                <v-list-item-title>
                  Hi {{ $store.state.userInfo.name }}
                </v-list-item-title>
              </v-list-item>
              <v-list-item @click="changePassword">
                <v-list-item-title>
                  變更密碼
                </v-list-item-title>
              </v-list-item>
              <v-list-item
              @click="logOut"
              >
                <v-list-item-title>
                  登出
                  <v-icon>
                    mdi-logout
                  </v-icon>
                </v-list-item-title>
              </v-list-item>
            </v-list>
          </v-card>
        </v-menu>
      </v-app-bar>
      <v-row>
        <v-spacer />
        <v-col cols="11">
          <router-view />
        </v-col>
        <v-spacer />
      </v-row>
    </v-main>
    <v-snackbar
      v-model="$store.state.expiryFire"
    >
      憑證到期，請重新登入
    </v-snackbar>
    <v-dialog
      v-model="dialog"
      width="600">
      <v-card>
        <v-card-title>
          變更密碼
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col cols="6">
              <v-text-field
                v-model="newPassoword"
                :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
                :rules="[rules.required, rules.counter]"
                :type="show1 ? 'text' : 'password'"
                outlined
                prepend-inner-icon="mdi-lock"
                style="width:300px;margin:20px auto 0px auto;"
                color="#4DBCB8"
                placeholder="輸入新密碼"
                @click:append="show1 = !show1"
              ></v-text-field>
            </v-col>
            <v-col cols="6">
              <v-text-field
                v-model="confirmPassoword"
                :append-icon="show2 ? 'mdi-eye' : 'mdi-eye-off'"
                :rules="[rules.required, rules.counter]"
                :type="show2 ? 'text' : 'password'"
                outlined
                prepend-inner-icon="mdi-lock"
                style="width:300px;margin:20px auto 0px auto;"
                color="#4DBCB8"
                placeholder="請再輸入一次"
                @click:append="show2 = !show2"
              ></v-text-field>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-btn
            style="margin:auto;width:200px;"
            x-large
            outlined
            color="#4DBCB8"
            @click="sendNewPassword"
          >
            確認變更
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="barDialog"
      fullscreen
      hide-overlay
      transition="dialog-bottom-transition"
    >
      <v-card style="background-color:white;">
        <v-icon size="50" style="float:right;margin-right:10px;" @click="barDialog = false">
          mdi-close
        </v-icon><br>
        <v-list
          style="margin-top:60px;"
          nav
        >
          <v-list-item-group
            v-model="group"
            active-class="choosen"
          >
            <v-list-item
              v-for="title in $store.state.sideBar"
              :key="title.label"
              @click="goTo(title.url)"
            >
              <v-list-item-icon>
                <v-icon
                  size="40"
                  color="#4DBCB8">
                  {{ title.icon }}
                </v-icon>
              </v-list-item-icon>
              <v-list-item-title style="text-align:center;">
                <span style="font-size:26px">{{ title.label }}</span>
              </v-list-item-title>
            </v-list-item>
          </v-list-item-group>
        </v-list>
        <div class="drawer-footer" style="opacity:.3">
          v {{ version }}
        </div>
      </v-card>
    </v-dialog>
  </v-app>
</template>

<script>
import api from './api/task';
import accountapi from './api/account';

export default {
  name: 'App',

  data: () => ({
    barDialog: false,
    newPassoword: '',
    confirmPassoword: '',
    show1: false,
    show2: false,
    dialog: false,
    group: [],
    drawer: false,
    mini: false,
    rules: {
      required: (value) => !!value || '請輸入新密碼',
      counter: (value) => value.length > 0 || '請輸入新密碼',
    },
    version: '12',
    // drawer: true,
  }),
  mounted() {
    this.getVersion();
    this.getTask();
  },
  methods: {
    async getVersion() {
      try {
        const response = await fetch('/version.json');
        const data = await response.json();
        this.version = data.version;
      } catch (error) {
        console.error('Error fetching version:', error);
      }
    },
    goTo(url) {
      this.$router.push(url);
      this.barDialog = false;
    },
    changePassword() {
      this.dialog = true;
    },
    sendNewPassword() {
      if (this.newPassoword.length > 0 && this.confirmPassoword.length > 0) {
        if (this.newPassoword === this.confirmPassoword) {
          const changeData = {
            account: this.$store.state.userInfo.email,
            password: this.confirmPassoword,
          };
          accountapi.changePassword(changeData)
            .then(async ({ data }) => {
              if (data.success) {
                console.log(data, '::::::::::::');
              } else {
                console.log('err:', data);
              }
            });
          console.log('set new password!');
        } else {
          console.log('兩個密碼不相符');
        }
      } else {
        console.log('請輸入新密碼');
      }
    },
    logOut() {
      this.$router.push('/');
      localStorage.removeItem('token');
      localStorage.removeItem('email');
      localStorage.removeItem('position');
      localStorage.removeItem('name');
      localStorage.removeItem('id');
      localStorage.removeItem('image');
      localStorage.removeItem('ability');
    },
    async readTaskFromTasks(array) {
      const arr = [];
      array.forEach((ele) => {
        // this.$set(ele, 'images', ['']);
        // console.log('_+_+)+_+_+)+_+_+', ele);
        if (ele.children.length > 0) {
          this.readTaskFromTasks(ele.children);
        } else {
          // this.$set(ele, 'images', ['']);
          this.$store.state.abilities.push(ele);
        }
      });
      // await console.log(this.abilities, '++++++++');
      return arr;
    },
    async readTrafficFromTraffics(array) {
      const arr = [];
      array.forEach((ele) => {
        if (ele.children.length > 0) {
          this.readTrafficFromTraffics(ele.children);
        } else {
          this.$store.state.traffics.push(ele);
        }
      });
      // await console.log(this.abilities, '++++++++');
      return arr;
    },
    getTask() {
      api.getTask()
        .then(async ({ data }) => {
          if (data.success) {
            console.log(data, '::::::::::::');
            this.$set(this.$store.state, 'taskCategories', data.tasks);
            this.$set(this.$store.state, 'trafficCategories', data.traffics);
            this.$set(this.$store.state, 'areas', data.areas);
            this.$store.state.areas.push({ name: '總部' });
            this.readTaskFromTasks(data.tasks);
            this.readTrafficFromTraffics(data.traffics);
          } else {
            console.log('err:', data);
          }
        });
    },
  },
};
</script>
<style scoped>
.drawer-footer {
  margin-top: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 16px;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
}
</style>
